export default async function ({ $auth, redirect, route }) {
  // 如果有 appAuth 參數，則將 appAuth 設定為使用者 token
  const { appAuth } = route.query
  try {
    if (appAuth) {
      await $auth.setUserToken(appAuth)
    }
  } catch (err) {
    console.log(err)
    redirect(`/login?redirect=${route.path}`)
  }
}
