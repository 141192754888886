/**
 * 取得 YouTube 影片 ID。
 * @param {string} youtubeStr - 要從中提取 ID 的網址
 * @returns {string|undefined}
 */
export default {
  methods: {
    /**
     * 去除 YouTube 短代碼字串中 `?`、`/` 或 '&' 之後的所有參數。
     *
     * @note 此函數不適用於包含協議（如 https://）的 URL 字串
     * @param {String} shortcodeString - 參數字串
     * @returns {String}
     */
    stripParameters (shortcodeString) {
      // 分割參數或分割資料夾分隔符
      if (shortcodeString.includes('?')) {
        shortcodeString = shortcodeString.split('?')[0]
      }

      if (shortcodeString.includes('/')) {
        shortcodeString = shortcodeString.split('/')[0]
      }

      if (shortcodeString.includes('&')) {
        shortcodeString = shortcodeString.split('&')[0]
      }

      return shortcodeString
    },
    getYoutubeID (youtubeString) {
      let string_ = youtubeString

      // Remove the '-nocookie' flag from youtube urls
      string_ = string_.replace('-nocookie', '')

      // Remove time hash at the end of the string
      string_ = string_.replace(/#t=.*$/, '')

      // Strip the leading protocol
      string_ = string_.replace(/^https?:\/\//, '')

      // Shortcode
      const shortcode = /youtube:\/\/|youtu\.be\/|y2u\.be\//g

      if (shortcode.test(string_)) {
        const shortcodeid = string_.split(shortcode)[1]
        return this.stripParameters(shortcodeid)
      }

      // Shorts
      const shortsUrl = /\/shorts\//g
      if (shortsUrl.test(string_)) {
        return this.stripParameters(string_.split(shortsUrl)[1])
      }

      // V= or vi=
      const parameterv = /v=|vi=/g

      if (parameterv.test(string_)) {
        const array = string_.split(parameterv)
        return this.stripParameters(array[1].split('&')[0])
      }

      // /v/ or /vi/ or /watch/
      const inlinev = /\/v\/|\/vi\/|\/watch\//g

      if (inlinev.test(string_)) {
        const inlineid = string_.split(inlinev)[1]
        return this.stripParameters(inlineid)
      }

      // Format an_webp
      const parameterwebp = /\/an_webp\//g

      if (parameterwebp.test(string_)) {
        const webp = string_.split(parameterwebp)[1]
        return this.stripParameters(webp)
      }

      // /e/
      const eformat = /\/e\//g

      if (eformat.test(string_)) {
        const estring = string_.split(eformat)[1]
        return this.stripParameters(estring)
      }

      // Embed
      const embedreg = /\/embed\//g

      if (embedreg.test(string_)) {
        const embedid = string_.split(embedreg)[1]
        return this.stripParameters(embedid)
      }

      // ignore /user/username pattern
      const usernamereg = /\/user\/([a-zA-Z\d]*)$/g

      if (usernamereg.test(string_)) {
        return undefined
      }

      // User
      const userreg = /\/user\/(?!.*videos)/g

      if (userreg.test(string_)) {
        const elements = string_.split('/')
        return this.stripParameters(elements.pop())
      }

      // Attribution_link
      const attrreg = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/

      if (attrreg.test(string_)) {
        return this.stripParameters(string_.match(attrreg)[1])
      }

      // Live
      const livereg = /\/live\//g

      if (livereg.test(string_)) {
        const liveid = string_.split(livereg)[1]
        return this.stripParameters(liveid)
      }

      return undefined
    }
  }
}
