export default {
  setWindowWidth (state, WindowWidth) {
    state.windowWidth = WindowWidth
  },
  setAppVersion (state, { version, platform }) {
    state.appVersion[platform] = version
  },
  setRouteSave (state, data) {
    state.routerSave = _.cloneDeep(data)
  },
  setIsFromFacebookAds (state, data) {
    state.isFromFacebookAds = data
  },
  setBannerList (state, { type, data }) {
    state.bannerList[type] = _.cloneDeep(data)
  },
  setCityArea (state, data) {
    state.cityArea = _.cloneDeep(data)
  },
  setAllCity (state, data) {
    state.allCityArea = _.cloneDeep(data)
  },
  resetPersonalInfo (state) {
    state.personalInfo = {
      receipt: {},
      address: [],
      community: [],
      contacts: []
    }
  },
  setPersonalReceipt (state, { data }) {
    state.personalInfo.receipt = _.cloneDeep(data)
  },
  setPersonalAddress (state, { data }) {
    state.personalInfo.address = _.cloneDeep(data)
  },
  setUserDefaultCommunity (state, data) {
    state.userDefaultCommunity = data
  },
  setPersonalCommunity (state, { data }) {
    state.personalInfo.community = _.cloneDeep(data)
  },
  setPersonalContacts (state, { data }) {
    state.personalInfo.contacts = _.cloneDeep(data)
  },
  setChannelTypeList (state, { data }) {
    state.channelTypeList = _.cloneDeep(data)
  },
  toast (state, { isOpen, text = '', type = 'info' }) {
    state.toast = {
      ...state.toast,
      isOpen,
      text,
      type
    }
  },
  imageBrowser (state, { isOpen, imageList, activeIndex }) {
    state.imageBrowser.isOpen = isOpen
    state.imageBrowser.imageList = imageList
    state.imageBrowser.activeIndex = activeIndex
  },
  registerSuccess (state, { isOpen }) {
    state.registerSuccessDialog = isOpen
  },
  postBody: (state, postBody) => {
    state.postBody = postBody
  },
  postError: (state, postError) => {
    state.postError = postError
  },
  setAgentOrder: (state, { agentToken, adminConsultationOrderId, agentSource }) => {
    state.agentOrder.token = agentToken
    state.agentOrder.adminConsultationId = adminConsultationOrderId
    state.agentOrder.source = agentSource
  },
  setApplePayAvailable: (state, available) => {
    console.log('setApplePayAvailable', available)
    state.payment.applePay.available = available
  },
  setGooglePayAvailable: (state, available) => {
    state.payment.googlePay.available = available
  },
  setIsGuestMember (state, status) {
    state.isGuestMember = status
  },
  setCreateGuestAccountData (state, data) {
    state.guestPurchase.createGuestAccountData = data
  },
  setGeustPurchaseOrderStatus (state, status) {
    state.guestPurchase.orderSuccess = status
  },
  clearGeustPurchaseOrderStatus (state) {
    state.guestPurchase.orderSuccess = { status: false, serviceId: null, orderId: null }
  },
  clearGeustPurchaseData (state) {
    state.guestPurchase.createGuestAccountData = null
  },
  setServiceRatingDialogOpen (state, status) {
    state.serviceRatingDialogOpen = status
  },
  setSentryConfig (state, data) {
    Object.entries(data).forEach(([key, value]) => {
      state.sentryConfig[key] = value
    })
  },
  setIsApp (state, isApp) {
    state.isApp = isApp
  }
}
