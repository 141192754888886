<template>
  <v-dialog
    v-model="dialogOpen"
    class="dialog"
    content-class="yt-player__dialog"
    width="auto"
    @click:outside="dialogClose"
  >
    <div
      class="yt-player"
    >
      <div
        class="yt-player__overlay"
        @click="dialogClose"
      >
        <div
          class="yt-player__container"
          @click.stop
        >
          <div
            class="yt-player__close"
            :class="{ shorts: isShorts }"
            @click="dialogClose"
          >
            <i class="icon icon-close" />
          </div>
          <iframe
            v-if="videoId"
            :class="{ shorts: isShorts }"
            :src="videoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    open: Boolean,
    // YouTube 影片 ID
    videoId: {
      type: String,
      required: true
    },
    isShorts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogOpen: false,
      isMobile: false
    }
  },
  computed: {
    /**
     * 產生 YouTube 影片網址
     * @returns {string} YouTube embedded URL
     */
    videoUrl () {
      return `https://www.youtube.com/embed/${this.videoId}?autoplay=1&playsinline=1&mute=1`
    }
  },
  watch: {
    async open (val) {
      this.dialogOpen = val
    }
  },
  mounted () {
    this.dialogOpen = this.open
    this.isMobile = screen.width <= 768
  },

  methods: {
    /**
     * 關閉影片播放器
     */
    dialogClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .yt-player__dialog {
  border-radius: 12px;
  overflow: hidden;
}
.yt-player {
  &__overlay {
    display: flex;
    justify-content: center;
    overflow: hidden;
    background: #000;
  }

  &__container {
    max-width: 980px;
    position: relative;
    width: 100%;
    display: flex;
    iframe {
      aspect-ratio: 16 / 9;
      height: auto;
      width: 980px;
      max-height: 550px;
      z-index: 2;
      position: relative;
      background: #000;

      &.shorts {
        aspect-ratio: 9 / 16;
        width: 360px;
        max-height: none;
      }
    }
  }

  &__close {
    background-color: transparent;
    cursor: pointer;
    position: fixed;
    right: calc((100% - 980px) / 2 - 40px);
    top: 5%;
    z-index: 1;

    i {
      color: #ffffff;
      font-size: 24px;
    }

    @media (max-width: 1120px) {
      right: 32px;
    }

  }
}
@media screen and (max-width: $mobileWidth) {
  ::v-deep .yt-player__dialog {
    margin: 16px;
  }
  .yt-player {
    &__close {
      &.shorts {
        top: 16px;
        right: 8px;
      }
      i {
        font-size: 15px;
      }
    }
  }
}
</style>
