var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article-card__container article-card__container--column",class:{
    'article-card__container--column': _vm.display === 'column',
    'article-card__container--row': _vm.display === 'row',

  },style:({
    'width': _vm.width || '',
    'max-width': _vm.maxWidth || ''
  })},[_c('div',{staticClass:"article-card__card-container"},[_c('div',{staticClass:"article-card__img-container",class:{
        'mr-5': _vm.display === 'row' && !_vm.isMobile,
        'mb-3': _vm.display === 'column',
        'width': _vm.imgWidth || '',
        'height': _vm.imgHeight || ''
      }},[_c('v-lazy',[_c('v-img',{key:_vm.content.CoverPictureUrl,staticClass:"article-card__img-container__img",attrs:{"src":_vm.content.CoverPictureUrl,"lazy-src":_vm.content.CoverPictureUrl,"alt":_vm.content.Title,"width":_vm.imgWidth || '',"height":_vm.imgHeight || '',"aspect-ratio":_vm.aspectRatio || ''}})],1),_vm._v(" "),(_vm.showTitleOnImg)?_c('div',{staticClass:"backdrop"},[_c('div',{staticClass:"text-container"},[_c('p',[_vm._v(_vm._s(_vm.content.Title))])])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"article-card__content-container"},[_c('div',{staticClass:"article-card__category-container"},[_c('span',{staticClass:"article-card__category-container__text"},[_vm._v(_vm._s(_vm.content.MainType.Name + ' • ' + _vm.content.SubType.Name))])]),_vm._v(" "),(_vm.showTitle)?_c('div',{staticClass:"article-card__title-container"},[_c('h3',{staticClass:"article-card__title-container__text"},[_vm._v("\n          "+_vm._s(_vm.content.Title)+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.showContent)?_c('div',{staticClass:"article-card__text-container"},[_c('span',{staticClass:"article-card__text-container__text"},[_vm._v("\n          "+_vm._s(_vm.content.Summary)+"\n        ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"article-card__category-container article-card__category-container--bottom"},[_c('span',{staticClass:"article-card__category-container__time"},[_vm._v(" "+_vm._s(_vm.content.CreateTime))]),_vm._v(" "),_c('span',{staticClass:"article-card__category-container__spacer"}),_vm._v(" "),_c('span',{staticClass:"article-card__category-container__view"},[_c('img',{attrs:{"src":require("@/assets/image/eye.svg"),"alt":"觀看次數"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.content.ViewingCount))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }