<template>
  <div class="component-section__title">
    <h2 class="component-section__title__text">
      {{ title }}
    </h2>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.component-section__title {
  @include line-through-title;
  justify-content: flex-start;
  @media screen and (max-width: $mobileWidth) {
    &__text {
      font-size: pxTorem(18px);
      margin: 0;
    }
  }
}
</style>
