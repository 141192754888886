<template>
  <div
    class="article-card__container article-card__container--column"
    :style="{
      'width': width || '',
      'max-width': maxWidth || ''
    }"
    :class="{
      'article-card__container--column': display === 'column',
      'article-card__container--row': display === 'row',

    }"
  >
    <div class="article-card__card-container">
      <div
        class="article-card__img-container"
        :class="{
          'mr-5': display === 'row' && !isMobile,
          'mb-3': display === 'column',
          'width': imgWidth || '',
          'height': imgHeight || ''
        }"
      >
        <v-lazy>
          <v-img
            :key="content.CoverPictureUrl"
            class="article-card__img-container__img"
            :src="content.CoverPictureUrl"
            :lazy-src="content.CoverPictureUrl"
            :alt="content.Title"
            :width="imgWidth || ''"
            :height="imgHeight || ''"
            :aspect-ratio="aspectRatio || ''"
          />
        </v-lazy>
        <div
          v-if="showTitleOnImg"
          class="backdrop"
        >
          <div class="text-container">
            <p>{{ content.Title }}</p>
          </div>
        </div>
      </div>
      <div class="article-card__content-container">
        <div class="article-card__category-container">
          <span class="article-card__category-container__text">{{ content.MainType.Name + ' • ' + content.SubType.Name }}</span>
        </div>
        <div
          v-if="showTitle"
          class="article-card__title-container"
        >
          <h3 class="article-card__title-container__text">
            {{ content.Title }}
          </h3>
        </div>
        <div
          v-if="showContent"
          class="article-card__text-container"
        >
          <span class="article-card__text-container__text">
            {{ content.Summary }}
          </span>
        </div>
        <div class="article-card__category-container article-card__category-container--bottom">
          <span class="article-card__category-container__time"> {{ content.CreateTime }}</span>
          <span class="article-card__category-container__spacer" />
          <span class="article-card__category-container__view"><img
            src="@/assets/image/eye.svg"
            alt="觀看次數"
          > <span>{{ content.ViewingCount }}</span> </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    display: String,
    width: String,
    maxWidth: String,
    imgWidth: String,
    imgHeight: String,
    aspectRatio: Number,
    showContent: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showTitleOnImg: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {
        return {
          MainType: {
            Name: ''
          },
          SubType: {
            Name: ''
          },
          Title: '',
          ViewingCount: 0,
          CreateTime: '',
          Summary: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  }
}
</script>

<style lang="scss" scoped>
.article-card {
  &__container {
    cursor: pointer;
    &--row {
      .article-card__card-container {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  &__card-container {
    padding: 10px;
    &:hover {
      box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.16);
      transition: ease-in-out 200ms;
      border-radius: 8px;
      .article-card__img-container__img {
        transform: scale(1.1);
      }
    }
  }
  &__content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__img-container {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: 2;
    border-radius: 8px;
    align-self: flex-start;
    &.mr-5 {
      margin-right: 20px;
    }
    &.mb-3 {
      margin-bottom: 12px;
    }
    &__img {
      z-index: 1;
      width: 100%;
      object-fit: cover;
      display: block;
      position: relative;
      transition: ease-in-out 200ms;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #fff;
      opacity: 0;
      transition: opacity .3s;
      pointer-events: none;
    }
  }
  &__category-container {
    display: flex;
    &--bottom {
      margin-top: auto;
      line-height: pxTorem(16px);
    }
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    &__text {
      color: $mainColor;
      font-weight: bold;
      font-size: pxTorem(15px);
      line-height: 1rem;
      background-color: $lightGrayColor;
      border-radius: 10px;
      padding: 4px 8px;
    }
    &__view {
      font-size: pxTorem(15px);
      color: $midGrayColor;
      display: flex;
      span, img {
        vertical-align: middle;
        display: inline-block;
      }
      img {
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
    }
    &__spacer {
      margin: 0 8px;
      color: #d2d2d2;
    }
    &__time {
      color: $midGrayColor;
      font-size: pxTorem(15px);
    }
  }
  &__title-container {
    &__text {
      color: $fontColor;
      font-weight: bold;
      font-size: pxTorem(17px);
      margin-top: 4px;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;
    }
  }
  &__text-container {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
    &__text {
      color: $fontColor;
      font-size: 15px;
    }
  }
}
.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 8px 8px;
  padding: 15px 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  .text-container {
    color: $whiteColor;
    font-weight: bold;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .article-card {
    &__category-container {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &__title-container {
      &__text {
        margin-top: 0px;
      }
    }
  }
}
</style>
