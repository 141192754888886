import goTo from 'vuetify/es5/services/goto'
import Vue from 'vue'
export default {
  async getBannerList ({ dispatch, state }, postData) {
    try {
      const userDefaultCommunity = await dispatch('getUserDefaultCommunity', null, { root: true })
      let bannerList = []
      if (!state.isApp) {
        if (userDefaultCommunity?.AreaId) {
          postData.RegionList = [userDefaultCommunity.AreaId]
        } else {
          postData.RegionList = []
        }
        const { Data } = await this.$axios.$get('/api/v2/Banner/GetBannerList', { params: postData, useCache: true })
        if (Data && Data.length) {
          bannerList = Data.filter(banner => banner.img_uri)
            .map(banner => {
              if (banner && banner.img_uri && banner.img_uri.substring(0, 5) === 'http:') {
                banner.img_uri = 'https:' + banner.img_uri.substring(5)
              }
              return banner
            })
        }
      } else {
        const { Data } = await this.$axios.$get('/api/Banner/HomePage', {
          params: {
            RegionId: userDefaultCommunity?.AreaId || 0,
            ComId: (userDefaultCommunity?.Id) || 0,
            IsWeb: false
          },
          useCache: true
        })
        if (Data && Data.Banners) {
          bannerList = Data.Banners.filter(banner => banner.BannerUrl).map(banner => {
            banner.img_uri = banner.BannerUrl
            if (banner && banner.img_uri && banner.img_uri.substring(0, 5) === 'http:') {
              banner.img_uri = 'https:' + banner.img_uri.substring(5)
            }
            banner.link_uri = banner.LinkUrl
            banner.title = banner.Title
            return banner
          })
        }
      }
      return bannerList
    } catch (err) {
      console.log(err)
      return []
    }
  },
  async getCityArea ({ commit, state }) { // 較少地區
    if (state.cityArea.length > 0) return state.cityArea
    const { Data } = await this.$axios.$get('/api/CityArea/GetCityAreaData')
    commit('setCityArea', Data)
    return Data
  },
  async getAllCity ({ commit, state }) { // 全部縣市
    if (state.allCityArea.length > 0) return state.allCityArea
    const { Data } = await this.$axios.$get('/api/Regions/GetCityList')
    commit('setAllCity', Data)
    return Data
  },
  async getAllArea ({ commit, state }, cityId) { // 全部縣市的地區
    const theCityIndex = _.findIndex(state.allCityArea, { region_id: cityId })
    const theCity = theCityIndex > -1 ? state.allCityArea[theCityIndex] : {}
    if (theCity && theCity.areaList && theCity.areaList.length > 0) return theCity.areaList
    const { Data } = await this.$axios.$get('/api/Regions/GetAreaList', { params: { region_id: cityId } })
    const allCityArea = _.cloneDeep(state.allCityArea)
    allCityArea.splice(theCityIndex, 1, {
      ...theCity,
      areaList: Data
    })
    commit('setAllCity', allCityArea)
    return Data
  },
  async getUserDefaultCommunity ({ commit, state }) {
    if (!this.$auth.loggedIn) return null
    if (state.userDefaultCommunity) return state.userDefaultCommunity
    try {
      const { data } = await this.$axios.get('/api/KingnetAccount/GetUserCommunity')
      const defaultCommunity = data.Data.find(com => com.DefaultCommunity)
      if (defaultCommunity?.City?.substring(0, 1) === '台') {
        defaultCommunity.City = '臺' + defaultCommunity.City.substring(1)
      }
      commit('setUserDefaultCommunity', defaultCommunity || {})
      return defaultCommunity || {}
    } catch (error) {
      return Promise.reject()
    }
  },
  async getPersonalReceipt ({ commit, state }) {
    // if (Object.keys(state.personalInfo.receipt).length > 0) return state.personalInfo.receipt
    const { data } = await this.$axios.get('/api/KingnetAccount/Invoice/List')
    commit('setPersonalReceipt', { data: data.Data })
    return data.Data
  },
  async getPersonalAddress ({ commit, state }) {
    const { data } = await this.$axios.get('/api/KingnetAccount/Address')
      .catch(error => Promise.reject(error))
    commit('setPersonalAddress', { data: data.Data })
    return data.Data
  },
  async getPersonalCommunity ({ commit, state }) {
    const { data } = await this.$axios.get('/api/KingnetAccount/ServiceAccountTablet')
      .catch(error => Promise.reject(error))
    commit('setPersonalCommunity', { data: data.Data })
    return _.cloneDeep(data.Data)
  },
  async getPersonalContacts ({ commit, state }) {
    const { data } = await this.$axios.get('/api/Account/GetPersonalFrequentlyUsed', {
      params: { UsedType: 'EC_OrderContacts' }
    })
      .catch(error => Promise.reject(error))
    commit('setPersonalContacts', { data: data.Data })
    return data.Data
  },
  async setPersonalContacts ({ commit, state }, contacts) {
    const { data } = await this.$axios.put('/api/Account/SetPersonalFrequentlyUsed', {
      UsedType: 'EC_OrderContacts', Settings: contacts
    })
      .catch(error => Promise.reject(error))
    commit('setPersonalContacts', { data: contacts })
    return contacts
  },
  async getChannelTypes ({ commit, state }) {
    const { data } = await this.$axios.get('/api/Order/GetChannelTypes')
      .catch(error => Promise.reject(error))
    commit('setChannelTypeList', { data: data.Data })
    return data.Data
  },
  async login ({ commit, state }, { Account, Password = '', UserType = 'Kingnet', IdToken = '', UserId = '' }) {
    const { data } = await this.$auth.loginWith('local', {
      data: {
        UserType,
        Account,
        Password,
        IdToken,
        UserId
      }
    })
      .catch(error => {
        return Promise.reject(error.response.data.ErrorMessage)
      })
    this.$axios.setToken(this.$auth.getToken('local'))
    return data.Data
  },
  async thirdLogin ({ dispatch }, { Account, Password = '', UserType = '', IdToken = '', UserId = '' }) {
    try {
      const { data } = await this.$serviceAccountSetApi.post('/ServiceAccountSet/ECWebLogin', {
        UserType,
        Account,
        Password,
        IdToken,
        UserId
      })
      await this.$auth.setUserToken(data.Data.Token)
      return data.Data
    } catch (error) {
      // LoginErrorCode = {
      //   401005: 'Google 登入失敗',
      //   401006: 'Facebook 登入失敗',
      //   401007: 'Apple 登入失敗',
      //   401008: '帳號不存在',
      //   401000: '帳號或密碼錯誤',
      //   403005: '無註冊過此第三方帳號',
      //   403007: '第三方尚未綁定手機',
      // }
      const errorObj = {
        isError: false,
        errorMessage: ''
      }
      switch (error.response.data.ErrorMessage.Code) {
        case 401005:
          errorObj.isError = true
          errorObj.errorMessage = 'Google登入失敗'
          break
        case 401006:
          errorObj.isError = true
          errorObj.errorMessage = 'Facebook登入失敗'
          break
        case 401007:
          errorObj.isError = true
          errorObj.errorMessage = 'Apple登入失敗'
          break
        case 401000:
          errorObj.isError = true
          errorObj.errorMessage = '帳號或密碼錯誤'
          break
        default:
          console.log(error.response.data.ErrorMessage.Code)
          Vue.prototype.$alert({
            title: '社群帳號尚未連結手機會員',
            messageHTML: '若您有手機會員，登入後即可連結社群帳號啟用快速登入。<br />若您尚未註冊手機會員，註冊後即可連結社群帳號啟用快速登入。',
            persistent: true,
            btn: [
              {
                btnText: '確認',
                btnColor: 'primary',
                btnClick: () => {
                  Vue.prototype.$alert.close()
                }
              }
            ]
          })
          break
      }
      if (errorObj.isError) {
        dispatch('toast', {
          isOpen: true,
          text: errorObj.errorMessage,
          type: 'error'
        })
      }
      return Promise.reject(error.response.data.ErrorMessage)
    }
  },
  async logOut ({ commit, dispatch }) {
    this.$auth.setToken(false)
    this.$auth.setRefreshToken(false)
    // TODO: 取消登出後移除 toRoute ?
    this.$auth.$storage.removeState('toRoute') //
    sessionStorage.removeItem('toRoute') //
    this.$auth.$storage.removeLocalStorage('googleToken')
    this.$axios.setHeader('Authorization', false)
    commit('resetPersonalInfo')
    commit('setIsGuestMember', null)
    commit('setUserDefaultCommunity', null)
    dispatch('service/resetFilteredServiceData')
    await this.$auth.logout()
  },
  async nuxtServerInit ({ commit }, { req }) {
    const auth = null
    try {
      // const data = await this.$auth.fetchUser()
    } catch (error) {
    }
    // this.$axios.setToken(this.$auth.getToken('local'), 'Bearer')
    // const data = await this.$auth.fetchUser()
  },
  async toast ({ commit }, { isOpen, text = '', type = 'info' }) {
    commit('toast', {
      isOpen,
      text,
      type
    })
  },
  async imageBrowser ({ commit }, { isOpen, imageList, activeIndex = 0 }) {
    commit('imageBrowser', {
      isOpen,
      imageList,
      activeIndex
    })
    document.querySelector('html').style.overflowY = isOpen ? 'hidden' : 'auto'
  },
  async registerSuccess ({ commit }, { isOpen, scrollTop = false }) {
    commit('registerSuccess', { isOpen })
    if (scrollTop) {
      goTo(0)
    }
  },
  async getAppversionFromFirebase ({ commit }) {
    if (process.server) return // Server-side

    const { remoteConfigVender, remoteConfigIsSupported, remoteConfigDefaults } = require('~/plugins/firebase')

    const setAppVersionForPlatforms = (getSource) => {
      ['ios', 'android'].forEach(async (platform) => {
        const version = getSource(`smartdaily_appversion_${platform}`)
        commit('setAppVersion', { version, platform })
      })
    }

    if (!remoteConfigIsSupported) {
      setAppVersionForPlatforms((key) => remoteConfigDefaults[key])
      return
    }

    try {
      await remoteConfigVender.fetchAndActivate()
      setAppVersionForPlatforms((key) => remoteConfigVender.getString(key))
    } catch (err) {
      console.log('err', err)
      setAppVersionForPlatforms((key) => remoteConfigDefaults[key])
    }
  },
  // 把 query 資料存入 state(重新整理/更改路徑即登出)
  async setAgentOrder ({ dispatch, commit }, data) {
    const agentToken = decodeURIComponent(data.agentToken)
    await dispatch('logOut')
    const adminConsultationOrderId = data.orderId ? data.orderId : null
    const agentSource = data.source
    if (!agentSource.includes('GuestAgent')) {
      this.$auth.setUser({
        ...this.$auth.user,
        agentToken,
        APhone: data.phone,
        AName: data.name,
        AId: data.AId,
        ABirthday: data.birthday,
        AGender: data.gender
      })
      this.$axios.setToken(agentToken)
    }
    commit('setAgentOrder', { agentToken, adminConsultationOrderId, agentSource })
    return Promise.resolve()
  },
  async installTappay () {
    console.log('installTappay')
    const {
      install,
      importGoogleSdk,
      setUp
    } = require('~/plugins/tappayVuex')
    if (typeof TPDirect === 'undefined') {
      console.log('TPDirect === undefined')
      await install()
    }
    try {
      if (typeof (google) === 'undefined') {
        throw new Error('no google')
      }
    } catch (error) {
      console.log('console google catch')
      console.log(error)
      await importGoogleSdk()
    }
    setUp()
    console.log('after setUp')
  },
  async resetPaymentRequest ({ state }, { paymentId, amount }) {
    const {
      checkCanUseApplePay,
      checkCanUseGooglePay
    } = require('~/plugins/tappayVuex')
    if (paymentId === state.payment.applePay.id) {
      await checkCanUseApplePay(amount)
    } else if (paymentId === state.payment.googlePay.id) {
      await checkCanUseGooglePay(amount)
    }
  },
  async checkMobilePayment ({ commit, dispatch }, { amount }) {
    console.log('checkMobilePayment', amount)
    await dispatch('installTappay')
    console.log('after installTappay')
    const {
      canUseTappay,
      isAndroidDevice,
      isIosDevice,
      checkCanUseApplePay,
      checkCanUseGooglePay,
      setApplePay,
      setGooglePay
    } = require('~/plugins/tappayVuex')

    if (!canUseTappay()) return
    if (isAndroidDevice()) {
      setGooglePay()
      commit('setGooglePayAvailable', await checkCanUseGooglePay(amount))
    }
    if (!isAndroidDevice()) { // android裝置不能apple pay
      setApplePay()
      commit('setApplePayAvailable', await checkCanUseApplePay(amount))
    }
  },
  async getPrime ({ state }, { paymentId }) {
    console.log('action getPrime')
    const {
      getPrime
    } = require('~/plugins/tappayVuex')
    let payment
    if (paymentId === state.payment.applePay.id) {
      // Apple Pay
      payment = 'apple-pay'
    } else if (paymentId === state.payment.googlePay.id) {
      // Google Pay
      payment = 'google-pay'
    }
    return await getPrime(payment)
  },
  async mobilePay ({ _ }, { mobilePrime, HashCode }) {
    console.log('action mobilePay HashCode', HashCode)
    if (!mobilePrime || !HashCode) Promise.reject(new Error('no enough data'))
    try {
      console.log('action mobilePay mobilePrime', mobilePrime)
      const { data } = await this.$staticEcsApi.post('/api/v2/Order/AppPayment', {
        HashCode,
        Prime: mobilePrime
      })
      console.log('action mobilePay axios', data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async checkIsGuestMember ({ state, commit }, postData) {
    try {
      if (!postData || !this.$auth.loggedIn || this.$auth.user.Type !== 3) {
        commit('setIsGuestMember', false)
        return
      }
      if (state.isGuestMember !== null) return
      const { data } = await this.$axios.get('/api/KingnetAccount/CheckIsGuestMemberByPhone', { params: postData })
      commit('setIsGuestMember', data.Data.IsGuestMember)
    } catch (err) {
      console.log(err)
      commit('setIsGuestMember', false)
    }
  },
  async checkIsOpenTappayAlert () {
    const isOpenTappayAlert = await this.$axios.get('/api/Payment/TappayAlertRecord/CheckIsOpen').catch(e => { console.log(e) })
    if (isOpenTappayAlert?.data?.Data) {
      const tappayAlert = () => new Promise((resolve) => {
        Vue.prototype.$alert({
          title: '系統提示',
          message: '系統偵測當前銀行端可能繁忙中，若遇到付款失敗情況請稍後再進行嘗試，謝謝配合',
          btnText: '我知道了',
          persistent: true,
          btnDonFun: () => {
            Vue.prototype.$alert.close()
            resolve(true)
          }
        })
      })
      await tappayAlert()
    }
  },
  async installFBSDK () {
    if (!process.browser) return
    if (window.FB) return
    return new Promise((resolve) => {
      window.fbAsyncInit = function () {
        FB.init({
          appId: 1794007497292036,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v16.0'
        })
      };
      (function (d, s, id) {
        let js; const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) { return }
        js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/zh_TW/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
        js.onreadystatechange = js.onload = () => {
          if (!js.readyState || /loaded|complete/.test(js.readyState)) {
            setTimeout(function () {
              resolve()
            }, 500)
          }
        }
      }(document, 'script', 'facebook-jssdk'))
    })
  },
  async installLineSDK () {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = 'https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js'
      script.defer = 'defer'
      script.onreadystatechange = script.onload = () => {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(function () {
            console.log('resolve')
            resolve()
          }, 500)
        }
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }
}
