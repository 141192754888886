<template>
  <div>
    <div
      v-if="serviceList.length"
      class="community-service-container"
    >
      <div
        ref="communityServiceSwiper"
        v-swiper:community-service="swiperOptions"
        class="community-service-swiper"
        @slideChange="bannerSlideChangeHandler()"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(service, index) in serviceList"
            :key="index"
            class="swiper-slide community-service-swiper-slide"
          >
            <div class="community-service-swiper__card">
              <div class="community-service__image">
                <a
                  class="community-service__link"
                  :href="goServiceDetail(service.ServiceId)"
                >
                  <img
                    :src="service.ImageUrl"
                    :alt="service.Title"
                  >
                </a>
              </div>
              <div class="community-service__content">
                <div class="community-service__tag-list">
                  <p
                    class="community-service__tag-list__tag eventOrange"
                  >
                    優惠中
                  </p>
                </div>
                <h3 class="community-service__title">
                  {{ service.Title.length > 10 ? service.Title.slice(0, 10) : service.Title }}
                </h3>
              </div>
              <div
                v-if="service.Description"
                class="community-service__footer"
              >
                <p class="community-service__description">
                  {{ service.Description > 16 ? service.Description.slice(0, 16) : service.Description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="community-service__loading"
    >
      <v-skeleton-loader
        v-for="x in 8"
        :key="x"
        class="homepage-loader__card-container__card"
        type="card"
      />
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   serviceList: {
  //     type: Array,
  //     default: null
  //   }
  // },
  data () {
    return {
      serviceList: [],
      swiperOptions: {
        slidesPerView: 'auto',
        slidesPerColumnFill: 'row',
        spaceBetween: 8,
        loop: true,
        loopedSlides: 20
      },
      activeIndex: 0
    }
  },
  mounted () {
    setTimeout(() => {
      this.serviceList = [
        {
          Title: '免費冷氣健檢\n免費冷氣',
          Description: '專業技師到府，當日檢查完工超快速',
          ImageUrl: 'https://picsum.photos/id/350/320/320',
          ServiceId: 832
        },
        {
          Title: '社區寄包裹免出門',
          Description: '到府收件超方便',
          ImageUrl: 'https://picsum.photos/id/590/320/320',
          ServiceId: 766
        },
        {
          Title: 'Community Service 3',
          Description: 'Description 3',
          ImageUrl: 'https://picsum.photos/id/155/320/320',
          ServiceId: 796
        },
        {
          Title: 'Community Service 4',
          Description: 'Description 4',
          ImageUrl: 'https://picsum.photos/320/320',
          ServiceId: 707
        },
        {
          Title: 'Community Service 5',
          Description: 'Description 5',
          ImageUrl: 'https://picsum.photos/320/320',
          ServiceId: 832
        }
      ]
    }, 300)
  },
  methods: {
    bannerSlideChangeHandler () {
      const { isBeginning, isEnd, realIndex } = this.$refs.communityServiceSwiper.swiper
      this.activeIndex = realIndex
      if (isBeginning || isEnd) {
        this.resetSwiper()
      }
    },
    resetSwiper () {
      this.$refs.communityServiceSwiper?.swiper?.loopDestroy()
      this.$refs.communityServiceSwiper?.swiper?.loopCreate()
      this.$refs.communityServiceSwiper?.swiper?.update()
    },
    goServiceDetail (serviceId) {
      console.log('goServiceDetail', serviceId)
      return `https://inapp.kingnetsmart.com.tw/ad.html?page=LS_A_Intro&params1=${serviceId}&referrer=`
    }
  }
}
</script>

<style lang="scss" scoped>
$contentContainerMaxWidth: calc(263px * 4 + 24px * 3);
$contentContainerMaxWidthMargin: calc(263px * 4 + 24px * 3 + 100px);
.community-service {
  &-container {
    padding: 0;
    padding-left: calc((100vw - #{$contentContainerMaxWidth}) / 2);
    .swiper-slide {
      max-width: 160px;
      padding-bottom: 24px;
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 160px;
    pointer-events: none;
  }
  &__tag-list {
    display: flex;
    align-items: center;
    position: absolute;
    top: 6px;
    left: 8px;
    &__tag {
      padding: 2px 8px;
      font-weight: bold;
      font-size: pxTorem(15px);
      background: #fd773d;
      border-radius: 2px;
      &.title-tag {
        margin-right: 12px;
        font-size: pxTorem(12px);
      }
      &.eventOrange {
        color: $whiteColor;
      }
      &.mainColor {
        color: $mainColor;
        background-color: #f0fffd;
      }
    }
  }
  &__title {
    color: $whiteColor;
    font-size: pxTorem(16px);
    text-align: center;
    max-width: 110px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    @include lineClamp(3);
  }
  &__link {
    display: flex;
  }
  &__image {
    aspect-ratio: 1 / 1;
    width: 100%;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #eaeaea;
    position: relative;
    &::before {
      background-color: rgba(0, 0, 0, 0.3);
      content: '';
      display: flex;
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__footer {
    padding: 16px 12px;
    background-color: $whiteColor;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 2;
    margin: 0 8px;
    margin-top: -46px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
  &__description {
    font-size: pxTorem(13px);
    color: $midGrayColor;
    font-weight: bold;
    text-align: center;
    margin: 0;
    @include lineClamp(2);
  }
  &__loading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding: 0 16px;
    ::v-deep {
      .homepage-loader {
        &__card-container__card:nth-child(n) {
          flex-shrink: 0;
          width: 160px;
          margin-right: 8px;
          margin-bottom: 14px;
          .v-skeleton-loader{
            &__image {
              width: 160px;
              height: 160px;
            }
            &__card-heading {
              .v-skeleton-loader__bone {
                margin: 8px auto 0;
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
}
/* 手機板 */
@media screen and (max-width: $mobileWidth) {
  .community-service {
    &-container {
      padding-left: 16px;
    }
  }
}
@media screen and (min-width: $mobileWidth) and (max-width: $contentContainerMaxWidthMargin) {
  .community-service-container {
    padding-left: 5vw;
  }
}
</style>
