<template>
  <div class="basic-verticle-card">
    <div class="basic-verticle-card__img-container">
      <img
        class="basic-verticle-card__img basic-verticle-card__img--bordered"
        :src="errorImgShowSrc || sharePost.imgUrl"
        @error="imgLoadErrorHandler"
      >
      <span class="basic-verticle-card__img-tag mainColor">{{ sharePost.siteName }}</span>
    </div>
    <header class="basic-verticle-card__info-container">
      <h3 class="basic-verticle-card__title">
        {{ sharePost.title }}
      </h3>
      <p class="basic-verticle-card__desc">
        {{ sharePost.description }}
      </p>
    </header>
  </div>
</template>

<script>
export default {
  props: {
    sharePost: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      errorImgShowSrc: null
    }
  },
  methods: {
    imgLoadErrorHandler () {
      this.errorImgShowSrc = require('@/assets/image/article/default-cover.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@include basic-verticle-card;
.basic-verticle-card {
  &__img {
    height: 200px;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
  }
  &__desc {
    margin-top: auto;
    @include lineClamp(3);
  }
}
</style>
