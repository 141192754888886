<template>
  <div class="highlight-service">
    <div
      v-if="serviceList.length"
      class="highlight-service-container"
    >
      <div
        ref="highlightServiceSwiper"
        v-swiper:highlight-service="swiperOptions"
        class="highlight-service-swiper"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(group, groupIndex) in serviceGroupList"
            :key="groupIndex"
            class="swiper-slide highlight-service-swiper-slide"
          >
            <div
              v-for="(service, index) in group"
              :key="index"
              class="highlight-service__card"
            >
              <div class="highlight-service__image">
                <a
                  class="highlight-service__link"
                  :href="service.LinkUrl"
                >
                  <img
                    :src="service.IconUrl"
                    :alt="service.Title"
                  >
                </a>
              </div>
              <div class="highlight-service__content">
                <h3 class="highlight-service__title">
                  {{ service.Title }}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showBannerSwiperArrow"
          class="swiper-pagination highlight-service-swiper-pagination"
        />
      </div>
    </div>
    <div
      v-else
      class="highlight-service__loading"
    >
      <v-skeleton-loader
        v-for="x in 8"
        :key="x"
        class="homepage-loader__card-container__card"
        type="card"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
      serviceList: [],
      serviceGroupList: [],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        pagination: {
          el: '.highlight-service-swiper-pagination',
          clickable: true
        }
      },
      activeIndex: 0
    }
  },
  computed: {
    showBannerSwiperArrow () {
      return Math.ceil(this.serviceList.length / 8) > 1
    }
  },
  mounted () {
    setTimeout(() => {
      this.serviceList = [
        {
          Title: '行動通訊',
          IconUrl: 'https://app.6yuwei.com/img/test1.png',
          LinkUrl: 'https://inapp.kingnetsmart.com.tw/ad.html?page=LS_A_Option&params1=832&referrer='
        },
        {
          Title: '乘車服務',
          IconUrl: 'https://app.6yuwei.com/img/test3.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上藥局',
          IconUrl: 'https://app.6yuwei.com/img/test2.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上報告',
          IconUrl: 'https://app.6yuwei.com/img/test4.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上諮詢',
          IconUrl: 'https://app.6yuwei.com/img/test5.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上掛號',
          IconUrl: 'https://app.6yuwei.com/img/test6.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上藥局',
          IconUrl: 'https://app.6yuwei.com/img/test7.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上報告',
          IconUrl: 'https://app.6yuwei.com/img/test8.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上掛號',
          IconUrl: 'https://app.6yuwei.com/img/test6.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上藥局',
          IconUrl: 'https://app.6yuwei.com/img/test7.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上掛號',
          IconUrl: 'https://app.6yuwei.com/img/test6.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上藥局',
          IconUrl: 'https://app.6yuwei.com/img/test7.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上掛號',
          IconUrl: 'https://app.6yuwei.com/img/test6.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上藥局',
          IconUrl: 'https://app.6yuwei.com/img/test7.png',
          LinkUrl: 'https://www.google.com'
        },
        {
          Title: '線上報告',
          IconUrl: 'https://app.6yuwei.com/img/test8.png',
          LinkUrl: 'https://www.google.com'
        }
      ]
      for (let i = 0; i < Math.ceil(this.serviceList.length / 8); i++) {
        this.serviceGroupList.push(this.serviceList.slice(i * 8, (i + 1) * 8))
      }
    }, 300)
  },
  methods: {
    bannerSlideChangeHandler () {
      const { isBeginning, isEnd, realIndex } = this.$refs.highlightServiceSwiper.swiper
      this.activeIndex = realIndex
      console.log('bannerSlideChangeHandler', isBeginning, isEnd, realIndex)
      if (isBeginning || isEnd) {
        this.resetSwiper()
      }
    },
    resetSwiper () {
      this.$refs.highlightServiceSwiper?.swiper?.loopDestroy()
      this.$refs.highlightServiceSwiper?.swiper?.loopCreate()
      this.$refs.highlightServiceSwiper?.swiper?.update()
    },
    // slider action
    clickBannerPaginationHandler (index) {
      console.log('clickBannerPaginationHandler', index)
      this.$refs.highlightServiceSwiper?.swiper?.slideToLoop(index, 200, false)
      this.activeIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight-service {
  display: block;
  overflow: hidden;
  &-container {
    padding: 0 23px;
    margin: 0 -14px;
    ::v-deep {
      .swiper-pagination {
        bottom: 0;
        margin-top: 24px;
        position: static;
        .swiper-pagination-bullet {
          padding: 0;
          width: 8px;
          height: 8px;
          background-color: $lineColor;
          cursor: pointer;
          border-radius: 4px;
          margin-right: 8px;
          margin-left: 0;
          transition: all 0.3s;
          &:first-child {
            border-radius: 4px;
          }
          &:last-child {
            border-radius: 4px;
          }
          &-active {
            background: $mainColor;
            width: 16px;
          }
        }
      }
      .highlight-service {
        &-swiper-slide {
          margin-top: 0;
          display: flex;
          flex-wrap: wrap;
        }
        &__card {
          width: 25%;
          padding: 0 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 12px;
          &:nth-of-type(n + 4) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &__title {
    font-size: pxTorem(13px);
    font-weight: bold;
    text-align: center;
  }
  &__image {
    max-width: 60px;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__link {
    display: flex;
  }
  &__loading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 16px;
    ::v-deep {
      .homepage-loader {
        &__card-container__card:nth-child(n) {
          flex-shrink: 0;
          width: calc(25% - 8px);
          margin-right: 8px;
          .v-skeleton-loader{
            &__image {
              width: 100%;
              max-width: 60px;
              margin: 0 auto;
              aspect-ratio: 1 / 1;
              height: auto;
            }
            &__card-heading {
              .v-skeleton-loader__bone {
                margin: 6px auto 0;
                width: 60%;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
