<template>
  <v-app>
    <div class="body">
      <nuxt class="empty-page" />
      <component-alert />
      <component-toast />
      <component-image-browser />
    </div>
  </v-app>
</template>

<script>
import ComponentAlert from '@/components/CompomentAlert'
import ComponentToast from '@/components/ComponentToast.vue'
import ComponentImageBrowser from '@/components/ComponentImageBrowser.vue'

export default {
  components: {
    ComponentAlert,
    ComponentToast,
    ComponentImageBrowser
  },
  async mounted () {
    await this.$nextTick()
    await this.$awaitSetTimeout(300)
    this.$nuxt.$loading.finish()
  }
}
</script>

<style lang="scss" scoped>
.empty-page {
  height: 100%;
}
</style>
