<template>
  <div
    class="back-to-top"
    :class="{ 'show': goTopShow }"
    @click="goTop"
  >
    <i
      class="icon-chevron-down"
    />
  </div>
</template>

<script>
const notShowPage = ['']
export default {
  name: 'ButtonBackToTop',
  data () {
    return {
      positionShow: false
    }
  },
  computed: {
    goTopShow () {
      return !this.notShowPageActive && this.positionShow
    },
    notShowPageActive () {
      return notShowPage.includes(this.$nuxt.$route.name)
    }
  },
  mounted () {
    const wrap = document.querySelector('body')
    if (this.$store.state.isMobile) {
      wrap.addEventListener('touchmove', this.listenTouchmove)
    } else {
      window.addEventListener('scroll', this.listenTouchmove)
    }
  },
  beforeDestroy () {
    const wrap = document.querySelector('body')
    wrap.removeEventListener('touchmove', this.listenTouchmove)
    window.removeEventListener('scroll', this.listenTouchmove)
  },
  methods: {
    async goTop () {
      if (!this.goTopShow) return
      this.$gaLogEvent('Back to Top', `${this.$meta().refresh().metaInfo.title}`)
      const headerHeight = document.querySelector('#header').offsetHeight
      const goTopOffsetY = document.querySelector('.go-top-container')?.offsetTop || 0 // 可自訂滑動位置(在外層元素加上go-top-container)
      await this.$vuetify.goTo(goTopOffsetY, { duration: 300, offset: headerHeight, easing: 'easeInOutQuad' })
      this.listenTouchmove()
    },
    listenTouchmove () {
      const scrollTop = window.scrollY
      this.positionShow = scrollTop >= screen.height / 2
    }
  }
}
</script>
<style lang="scss" scoped>
.back-to-top {
  $borderSize: 2px;
  $size: calc(50px - #{$borderSize});
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 60px;
  bottom: calc(60px + var(--safe-area-inset-bottom));
  right: 15px;
  right: calc(15px + var(--safe-area-inset-right));
  height: $size;
  line-height: $size;
  width: $size;
  text-align: center;
  box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  color: $mainColor;
  font-size: 30px;
  background-color: #fff;
  transform: rotate(180deg);
  transition: all .3s ease-in-out;
  opacity: 0;
  z-index: 99;
  &.show {
    opacity: 1;
    cursor: pointer;
  }
}
@media screen and (min-width: $mobileWidth) {
  .back-to-top {
    $borderSize: 2px;
    $size: calc(55px - #{$borderSize});
    height: $size;
    line-height: $size;
    width: $size;
    bottom: 15px;
    bottom: calc(15px + var(--safe-area-inset-bottom));
    right: 18px;
    right: calc(18px + var(--safe-area-inset-right));
    &.show {
      opacity: 1;
    }
  }
}
</style>
